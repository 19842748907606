import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useFrontload } from 'react-frontload';

import Footer from '../Footer/Footer';
import NewsletterSection from '../NewsletterSection/NewsletterSection';
import RecentlyViewedProducts from '../RecentlyViewedProducts/RecentlyViewedProducts';
import StickyHeader from '../StickyHeader/StickyHeader';

// import { ROUTER_MATCH_SHAPE } from '../../state/router/router';

import styles from './InfoPage.module.scss';
import useMediaQuery from '../../utils/useMediaQuery';
import { getValidResponseBody } from '../../api';
// import OfflinePage from '../OfflinePage/OfflinePage';

const InfoPage = ({ config, profile, match }) => {
  const { data: frontloadData, frontloadMeta } = useFrontload('info-page', async ({ contentApi }) => ({
    latestPages: await contentApi.getLatestPages(),
  }));
  const latestPages = getValidResponseBody(frontloadData?.latestPages);

  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedFragment, setSelectedFragment] = useState(null);
  const { isGreaterOrEqualTo, BREAKPOINTS } = useMediaQuery();

  const isGreaterThanMobile = isGreaterOrEqualTo(BREAKPOINTS.MEDIUM);
  const location = useLocation();
  const hash = location?.hash;

  /** If we're in a 'medium' or larger breakpoint we smooth-scroll into the right section by click on sidebar link  */
  useEffect(() => {
    if (!latestPages) {
      return;
    }

    if (!isGreaterThanMobile) {
      setSelectedPage(match.path.substr(1));
      window.scrollTo(0, 0);
      return;
    }

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        const slugToSearch = (hash && hash.substr(1)) || match.path.substr(1);
        const elm = document.getElementById(slugToSearch);

        if (!elm) {
          return;
        }
        window.scrollTo({ behavior: 'smooth', left: 0, top: elm.offsetTop - 100 });

        if (hash) {
          const h1 = elm.parentNode.parentNode.querySelector('h1');
          setSelectedPage(h1.id);
          setSelectedFragment(slugToSearch);
        } else {
          setSelectedPage(elm.id);
          setSelectedFragment(null);
        }
      });
    });
  }, [latestPages, match.path, isGreaterThanMobile, hash]);

  if (frontloadMeta.pending) {
    return null;
  }

  if (frontloadMeta.error || !frontloadData?.latestPages?.ok) {
    // FIXME: provide offline check
    // if (offline) {
    //   return <OfflinePage />;
    // }
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`${location.pathname.replace('/', '')} - ${config.shopName}`}</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:url"
          content={`${config.baseUrl}${location.pathname.endsWith('/') ? location.pathname : location.pathname + '/'}`}
        />
        <link
          rel="canonical"
          href={`${config.baseUrl}${location.pathname.endsWith('/') ? location.pathname : location.pathname + '/'}`}
        />
      </Helmet>
      <StickyHeader />
      <div className={classnames(styles.content, 'container')} id="readyForCrawler">
        <div className={classnames(styles.rowWrapper, 'row')}>
          <aside className={classnames(styles.aside, 'col-12-xs col-3-xl')}>
            <nav>
              <ul className={styles.navTopLevel}>
                {latestPages
                  .filter((page) => page.slug !== config.cmsSlugs.termsAndConditions)
                  .map((page, pageIdx) => (
                    <li
                      className={classnames(styles.navTopLevelItem, {
                        [styles.navTopLevelItemActive]: page.slug === selectedPage,
                      })}
                      key={`navTopLevelItem_${page.slug}`}
                      data-testid={`navTopLevelItem_${page.slug}`}
                    >
                      <Link replace to={`/${page.slug}`}>
                        {page.title}
                      </Link>
                      <ul className={styles.navSecondLevel}>
                        {page.collection.map((item, itemIdx) => (
                          <li
                            className={classnames(styles.navSecondLevelItem, {
                              [styles.navSecondLevelItemActive]:
                                selectedFragment === `${pageIdx}_${itemIdx}_${item.slug}`,
                            })}
                            key={`navSecondLevelItem_${item.slug}`}
                            data-testid={`navSecondLevelItem_${item.slug}`}
                          >
                            <Link replace to={`/${page.slug}/#${pageIdx}_${itemIdx}_${item.slug}`}>
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </nav>
          </aside>
          <main className="col-12-xs col-9-xl">
            {latestPages
              .filter((page) => page.slug !== config.cmsSlugs.termsAndConditions)
              .map((page, pageIdx) => {
                return (
                  <section
                    className={classnames(styles.page, { [styles.pageActive]: page.slug === selectedPage })}
                    key={page.slug}
                  >
                    <h1 className={styles.h1} id={page.slug}>
                      {page.title}
                    </h1>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: page.excerpt }} />
                    <div className={styles.divider} />
                    {page.collection.map((item, itemIdx) => (
                      <div className="fragment" key={`${pageIdx}_${itemIdx}_${item.slug}`}>
                        <h2 className={styles.h2} id={`${pageIdx}_${itemIdx}_${item.slug}`}>
                          {item.title}
                        </h2>
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
                        <div className={styles.divider} />
                      </div>
                    ))}
                  </section>
                );
              })}
          </main>
        </div>
      </div>
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
    </>
  );
};

InfoPage.displayName = 'InfoPage';
InfoPage.propTypes = {
  // match: ROUTER_MATCH_SHAPE,
};

const mapStateToProps = ({ config, profile }) => ({ config, profile });
export default connect(mapStateToProps)(InfoPage);
